<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <ASuccessFour :api="api"/>
        <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">

        </v-skeleton-loader>
        <v-row v-else>
            <v-col
            class="mx-auto"
            cols="12"
            sm="6">
                <div class="d-flex justify-space-between">
                    <v-btn
                    class="mb-3"
                    color="primary"
                    @click="closeDetail">
                        <v-icon>
                            mdi-arrow-left-bold
                        </v-icon>
                    </v-btn>
                </div>
                <v-card class="pa-3">
                    <v-toolbar class="secondary white--text " >
                        <v-toolbar-title class="font-weigh-bold text-h5">
                            Claim Reward
                        </v-toolbar-title>
                    </v-toolbar>
                    <div v-if="rewards!=null" class="mt-3 pt-4 pb-0">
                       
                        <div class="d-flex justify-start">
                            <v-text-field 
                                v-model="rewards.username"
                                dense
                                disabled 
                                outlined
                                label="User">
                            </v-text-field>
                        </div>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="rewards.email"
                                    dense
                                    disabled 
                                    outlined
                                    label="Email">
                                </v-text-field>                                
                            </v-col>
                            <v-col>
                                <v-text-field
                                    v-model="rewards.mobile"
                                    dense 
                                    disabled 
                                    outlined
                                    label="Mobile">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <div class="d-flex justify-start">
                            <v-textarea 
                                v-model="rewards.address"
                                label="Address"
                                outlined
                                disabled
                                dense
                                >

                            </v-textarea>
                        </div>
                         <div class="d-flex justify-start">   
                            <v-text-field 
                                v-model="rewards.name"
                                dense 
                                disabled
                                label="Reward"
                                outlined>
                            </v-text-field>
                        </div>
                        <div 
                        class="">
                            <v-select
                            :items="statusOptions"
                            v-model="rewards.status"
                            dense
                            item-text="text"
                            item-value="value"
                            label="Status"
                            class="mb-0 pb-0"
                            style="margin-bottom: 0px; padding-bottom: 0px;"
                            outlined>
                            </v-select>
                        </div>
                        <div
                            class="d-flex justify-start">
                            <v-checkbox
                                v-model="isSendingEmail"
                                dense
                                outlined 
                                label="Send email">

                            </v-checkbox>

                        </div>
                        <div
                            class="d-flex justify-start"
                            v-if="isSendingEmail">
                            <v-autocomplete
                                v-model="rewardsSend"
                                :items="rewardSendOptions"
                                item-text="text"
                                item-value="value"
                                dense 
                                outlined
                                label="Email Template">
                            </v-autocomplete>

                        </div>
                        <!-- BOC : if the send email is the e-vourcher-->
                        <v-row >
                            <v-col
                                v-if="['evoucher','courier'].includes(rewardsSend) && isSendingEmail"
                                class="">
                                <v-row>
                                    <v-col>
                                        <v-text-field
                                            dense
                                            outlined
                                            v-model="rewardEmailTemplate.rewardName"
                                            label="Reward name">
                                        </v-text-field>
                                        <v-text-field
                                            dense 
                                            outlined
                                            v-model=rewardEmailTemplate.clientName
                                            label="Recipient name">
                                        </v-text-field>
                                        <v-text-field
                                            dense 
                                            outlined
                                            v-model=rewardEmailTemplate.clientEmail
                                            label="Recipient email">
                                        </v-text-field>
                                    
                                        <v-file-input
                                            v-if="rewardsSend==='evoucher'"
                                            accept="image/png, image/jpeg"
                                            color="primary"
                                            v-model="rewardEmailTemplate.attachment"
                                            dense 
                                            counter
                                            :show-size="1000"
                                            outlined 
                                            prepend-icon=""
                                            label="Please upload the coupon image">
                                        </v-file-input>
                                        <div
                                            v-if="rewardsSend==='courier'">
                                            <v-text-field
                                                dense 
                                                outlined
                                                v-model="rewardEmailTemplate.courierService"
                                                label="Courier service">
                                            </v-text-field>
                                            <v-text-field
                                                dense
                                                outlined
                                                v-model="rewardEmailTemplate.trackingNumber"
                                                label="Tracking number">
                                            </v-text-field>
                                            <v-textarea 
                                                v-model="rewardEmailTemplate.clientAddress"
                                                label="Recipient's address"
                                                outlined
                                                dense
                                                >
                                            </v-textarea>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field 
                                    label="Points Required"
                                    dense
                                    disabled
                                    outlined 
                                    v-model="rewards.required_points">
                                </v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                v-model="rewards.reward_quantity" 
                                dense
                                disabled
                                outlined
                                label="Quantity">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <div class="d-flex justify-content-start">
                            <v-text-field
                            label="Total Points Deducted"
                            dense
                            disabled 
                            outlined
                            v-model="rewards.points">
                            </v-text-field>
                        </div>
                        <v-row>
                            <v-col
                                class="pb-0">
                                <v-text-field
                                    label="User Points Before Deducted"
                                    v-model="rewards.before_points"
                                    dense
                                    disabled
                                    outlined>
                                </v-text-field>
                            </v-col>
                            <v-col
                                class="pb-0">
                                <v-text-field
                                    label="User Points After Deducted"
                                    v-model="rewards.after_points"
                                    dense
                                    disabled
                                    outlined>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <!--EOC-->
                        <div class="d-flex justify-start mt-4">
                            <v-textarea
                            v-model="rewards.remark"
                            label="Remark"
                            outlined
                            dense>
                            </v-textarea>
                        </div>
                      
                        <div class="d-flex justify-end">
                            <v-btn color="primary" plain @click="()=>{
                                $router.go(-1)
                            }">
                                Cancel
                            </v-btn>
                            <v-btn color="primary" :loading="api.isLoading" @click="validateInput">
                                Update
                            </v-btn>
                        </div>
                        <div
                            class="d-flex justify-end my-2">
                            <AError :api="api"/>

                        </div>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import ASuccessFour from '../../components/common/ASuccessFour.vue';
export default {
    components:{
    ASuccessFour
},
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
    isSendingEmail:false,
    rewardSendOptions: [
        {
            text:'E-voucher',
            value:'evoucher'
        },
        {
            text:'Logistic Courier',
            value:'courier'
        },
    ],
    rewardEmailTemplate:{
        rewardName:null,
        attachment:null,
        courierService:null,
        trackingNumber:null,
        clientName:null,
        clientAddress:null,
        clientEmail:null,
        clientMobile:null,
    },
    rewardsSend:null,
    rewards:null,
    isPending:false,
    headersTeam:[
        {
            text:'Name',
            value:'memberName',
            align:'start',
        },{
            text:'Email',
            value:'memail',
        }
    ],
    statusOptions:[
        {
            text:'In Progress',
            value:'Pending',
        },
        {
            text:'Completed',
            value:'Completed',
        }
    ],
    api:
        {
            isLoading : false,
            isError:false,
            error:null,
            url:null,
            isSuccesful:false,
            success:null,
        },
    }),
    created() {
        this.api.callbackReset = () => 
        {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) =>
        {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) =>
        {
            if(resp.class=="getRewardTransition"){
                this.rewards = resp.data;
                this.rewardEmailTemplate.rewardName = this.$_.clone(resp.data.name);
                this.rewardEmailTemplate.clientName = this.$_.clone(resp.data.username);
                this.rewardEmailTemplate.clientAddress = this.$_.clone(resp.data.address);
                this.rewardEmailTemplate.clientMobile = this.$_.clone(resp.data.mobile);
                this.rewardEmailTemplate.clientEmail = this.$_.clone(resp.data.email);
            }
            if(resp.class=="updateRewardTransitionStatus"){
                this.api.isSuccesful = true;
                this.api.success = "Succesfully Update The Claim Reward Status"
            }
            this.api.isError = false;
            this.api.isLoading = false;

        }
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            let fetchClaimRewardApi = this.fetchClaimReward();
            this.$api.fetch(fetchClaimRewardApi);
        },
        fetchClaimReward(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method ="GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/rewards/transition/"+this.$router.history.current.params.id;
            return tempApi;
        },
        validateInput(){
            this.isPending = true
        },
        submit(){
            this.isPending = false;
            let updateRewardStatusApi = this.updateRewardStatus();
            this.$api.fetch(updateRewardStatusApi);
        },
        updateRewardStatus(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/referral/rewards/transition/status/"+this.$router.history.current.params.id;
            let formData = new FormData;
            formData.append('status',this.rewards.status);
            formData.append('remark',this.rewards.remark);
            formData.append('isSendingEmail',this.isSendingEmail);
            formData.append('sendingEmailType',this.rewardsSend);
            formData.append('rewardName',this.rewardEmailTemplate.rewardName);
            let isNotUploadCoupon = this.isSendingEmail && this.rewardsSend=='evoucher' && this.rewardEmailTemplate.attachment==null
            if(isNotUploadCoupon) {
                this.api.isError = true;
                this.api.error = "Please upload the coupon image";
                return false;
            }
            let notFIllInCourier = this.isSendingEmail && this.rewardsSend=='courier' && (this.rewardEmailTemplate.courierService==null || this.rewardEmailTemplate.trackingNumber==null) 

            if(notFIllInCourier) {
                this.api.isError = true;
                this.api.error = "Please fill in the courier detail";
                return false;
            }
            
            formData.append('attachment',this.rewardEmailTemplate.attachment);
            // formData.append('attachment',this.rewardEmailTemplate.attachment);
            formData.append('courierService',this.rewardEmailTemplate.courierService);
            formData.append('trackingNumber',this.rewardEmailTemplate.trackingNumber);
            formData.append('clientName',this.rewardEmailTemplate.clientName);
            formData.append('clientAddress',this.rewardEmailTemplate.clientAddress);
            formData.append('clientMobile',this.rewardEmailTemplate.clientMobile);
            formData.append('clientEmail',this.rewardEmailTemplate.clientEmail)
            tempApi.params = formData;
            return tempApi;
        },
        closeDetail(){
            this.$router.go(-1);
        },
        cancelSubmit(){
            this.isPending = false;
        },

    }
}
</script>